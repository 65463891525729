{
  "name": "fastuna-ui",
  "version": "2.0.15",
  "description": "Библиотека компонентов Fastuna",
  "files": [
    "build",
    "src/global.d.ts"
  ],
  "exports": {
    ".": {
      "import": "./build/esm/index.js",
      "require": "./build/umd/index.js"
    },
    "./fonts.css": "./build/esm/static/styles/fonts.css",
    "./global.css": "./build/esm/static/styles/global.css",
    "./reset.css": "./build/esm/static/styles/reset.css"
  },
  "main": "./build/umd.js",
  "module": "./build/esm/index.js",
  "typings": "./build/esm/index.d.ts",
  "scripts": {
    "dev": "storybook dev -p 6006",
    "format": "prettier --write src/**/*.{ts,tsx,js,jsx,json}",
    "build": "rm -rf build && npm run build:all",
    "build:all": "npm run build:cjs && npm run build:umd && npm run build:esm",
    "build:cjs": "webpack --config-name cjs --mode production",
    "build:umd": "webpack --config-name umd --mode production",
    "build:esm": "tsc --project tsconfig.app.json && tsc-alias && tscp",
    "build:storybook": "tsc --project tsconfig.storybook.json && storybook build",
    "watch": "tsc-watch --project tsconfig.app.json --onSuccess \"npm run watch:postbuild\"",
    "watch:postbuild": "tsc-alias && tscp && yalc push",
    "deploy": "npm run build && npm publish"
  },
  "peerDependencies": {
    "react": "18.3.0",
    "react-dom": "18.3.0",
    "styled-components": "6.x"
  },
  "devDependencies": {
    "@babel/cli": "7.22.5",
    "@babel/core": "7.22.5",
    "@babel/preset-env": "7.22.5",
    "@babel/preset-react": "7.22.5",
    "@babel/preset-typescript": "7.22.5",
    "@storybook/addon-docs": "7.5.3",
    "@storybook/addon-essentials": "7.0.24",
    "@storybook/addon-interactions": "7.0.24",
    "@storybook/addon-links": "7.0.24",
    "@storybook/blocks": "7.0.24",
    "@storybook/react": "7.0.24",
    "@storybook/react-webpack5": "7.0.24",
    "@storybook/testing-library": "0.0.14-next.2",
    "@types/lodash.xor": "4.5.0",
    "@types/react": "18.3.5",
    "@types/react-dom": "18.3.0",
    "@types/webpack": "5.28.1",
    "@typescript-eslint/eslint-plugin": "5.59.8",
    "@typescript-eslint/parser": "5.59.8",
    "babel-loader": "9.1.2",
    "copy-webpack-plugin": "11.0.0",
    "css-loader": "7.1.2",
    "eslint": "8.42.0",
    "eslint-config-airbnb": "19.0.4",
    "eslint-config-airbnb-typescript": "17.0.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-import-resolver-typescript": "3.5.5",
    "eslint-mdx": "3.0.0",
    "eslint-plugin-import": "2.27.5",
    "eslint-plugin-jsx-a11y": "6.7.1",
    "eslint-plugin-mdx": "3.0.0",
    "eslint-plugin-prettier": "5.0.1",
    "eslint-plugin-react": "7.32.2",
    "eslint-plugin-react-hooks": "4.6.0",
    "husky": "8.0.3",
    "lint-staged": "13.2.0",
    "lodash.xor": "4.5.0",
    "postcss-styled-syntax": "0.4.0",
    "prettier": "3.1.0",
    "react": "18.3.0",
    "react-dom": "18.3.0",
    "storybook": "7.0.24",
    "style-loader": "4.0.0",
    "styled-components": "6.1.8",
    "stylelint": "15.4.0",
    "stylelint-config-standard": "32.0.0",
    "tsc-alias": "^1.8.6",
    "tsc-watch": "6.0.0",
    "tsconfig-paths-webpack-plugin": "4.0.1",
    "typescript": "4.9.5",
    "typescript-cp": "0.1.8",
    "webpack": "5.88.1",
    "webpack-cli": "5.1.4"
  },
  "dependencies": {
    "react-select": "5.7.3",
    "react-textarea-autosize": "^8.3.4",
    "verdaccio-github-token": "1.0.0",
    "rc-slider": "11.1.7"
  },
  "overrides": {
    "csstype": "3.1.2"
  },
  "engines": {
    "node": ">=20 <21"
  }
}
